import { graphql } from "gatsby";
import React from "react"
import MainLayout from "../../layouts/MainLayout"
import ReactMarkdown from "react-markdown"
import FirstLevelBanner from "../../components/our-work/first-level/Banner";
import SEO from "../../components/SEO"

const AboutThePatron = ({ data }) => {
  const find = '<img src="';
  const re = new RegExp(find, 'g');
  const doc = new RegExp('<a href="\/', 'g')

  return (
    <MainLayout>
      <SEO title={`${data.strapiAboutThePatron.Seo.title}`} description={`${data.strapiAboutThePatron.Seo.description}`} />
      <FirstLevelBanner sources={data.strapiAboutThePatron.heroImages} />
      <div className="about-the-patron">
        <h1 className="custom-header">{data.strapiAboutThePatron.Header}</h1>
        <ReactMarkdown
          source={data.strapiAboutThePatron.content.replace(re, `<img src="${process.env.API_URL}`).replace(doc, `<a href="${process.env.API_URL}/`)}
          escapeHtml={false}
          className="ck-content"
        />
      </div>
    </MainLayout>
  )
}

export default AboutThePatron;

export const pageQuery = graphql`  
  query {
    strapiAboutThePatron {
        Header
        content
        Seo {
          title
          description
          keywords
        }
        heroImages {
          desktopImage {
            localFile{
              publicURL
            }
          }
          mobileImage {
            localFile{
              publicURL
            }
          }
        }
    }
  }
`